import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ExpensesCategory } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>(
  'expensesCategoriesStore',
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAll: (
  state: object,
) => ExpensesCategory[] = featureAdapter.getSelectors(selectState).selectAll;

export const selectDetails = createSelector(
  selectState,
  (state: State) => state.details,
);

export const selectDefaultVatQuotesMap = createSelector(
  selectState,
  (state: State) => state.defaultVatQuotesMap,
);
