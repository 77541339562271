import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import {
  CreateExpensesCategoryRequest,
  UpdateExpensesCategoryRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ExpensesCategoriesService {
  constructor(private http: HttpClient) {}

  load(propertiesIds: number[]) {
    return this.http.get(
      `expenses_categories?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  loadDetails(expensesCategoryId: number) {
    return this.http.get(`expenses_category/${expensesCategoryId}`);
  }

  create(payload: CreateExpensesCategoryRequest) {
    return this.http.post(`expenses_category`, payload);
  }

  update(
    expensesCategoryId: number,
    payload: Partial<UpdateExpensesCategoryRequest>,
  ) {
    return this.http.put(`expenses_category/${expensesCategoryId}`, payload);
  }

  delete(expensesCategoryId: number, propertiesIds: number[]) {
    return this.http.delete(
      `expenses_category/${expensesCategoryId}?${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }
}
