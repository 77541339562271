import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ExpensesCategory } from '../../models';

export const featureAdapter: EntityAdapter<ExpensesCategory> = createEntityAdapter<
  ExpensesCategory
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<ExpensesCategory> {
  isLoading?: boolean;
  error?: any;
  details?: ExpensesCategory;
  defaultVatQuotesMap?: {
    [categoryId: number]: { [propertyId: number]: number };
  };
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  details: null,
  defaultVatQuotesMap: null,
});
