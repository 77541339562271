import { createAction, props } from '@ngrx/store';

import {
  CreateExpensesCategoryRequest,
  LoadExpensesCategoryDetailsResponse,
  UpdateExpensesCategoryRequest,
} from '../../models';
import { ExpensesCategory } from '../../models/objects/expenses-category';

export const loadRequest = createAction(
  '[Expenses Categories] Load Request',
  props<{ propertiesIds: number[] }>(),
);
export const loadSuccess = createAction(
  '[Expenses Categories] Load Success',
  props<{ data: ExpensesCategory[] }>(),
);
export const loadFailure = createAction(
  '[Expenses Categories] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Expenses Categories] Load Details Request',
  props<{ expense_category_id: number }>(),
);
export const loadDetailsSuccess = createAction(
  '[Expenses Categories] Load Details Success',
  props<{ data: LoadExpensesCategoryDetailsResponse }>(),
);
export const loadDetailsFailure = createAction(
  '[Expenses Categories] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Expenses Categories] Create Request',
  props<CreateExpensesCategoryRequest>(),
);
export const createSuccess = createAction(
  '[Expenses Categories] Create Success',
);
export const createFailure = createAction(
  '[Expenses Categories] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Expenses Categories] Update Request',
  props<UpdateExpensesCategoryRequest>(),
);
export const updateSuccess = createAction(
  '[Expenses Categories] Update Success',
);
export const updateFailure = createAction(
  '[Expenses Categories] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Expenses Categories] Delete Request',
  props<{ expense_category_id: number; propertiesIds: number[] }>(),
);
export const deleteSuccess = createAction(
  '[Expenses Categories] Delete Success',
  props<{ expense_category_id: number }>(),
);
export const deleteFailure = createAction(
  '[Expenses Categories] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Expenses Categories] Reset State');

export const resetDetails = createAction('[Expenses Categories] Reset Details');
