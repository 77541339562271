import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ExpensesCategory } from '../../models';
import { ExpensesCategoriesService } from '../../services/expenses-categories.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ExpensesCategoriesEffects {
  constructor(
    private dataService: ExpensesCategoriesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds }) =>
        this.dataService.load(propertiesIds).pipe(
          map(({ data }: IResponseSuccess<ExpensesCategory[]>) =>
            fromActions.loadSuccess({
              data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ expense_category_id }) =>
        this.dataService.loadDetails(expense_category_id).pipe(
          map(({ data }: IResponseSuccess<ExpensesCategory>) =>
            fromActions.loadDetailsSuccess({
              data: data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.create(payload).pipe(
          map(({ data }: IResponseSuccess<ExpensesCategory>) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('expenses_category'),
              }),
              type: 'success',
            });

            return fromActions.createSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ type, id: expensesCategoryId, ...payload }) =>
        this.dataService.update(expensesCategoryId, payload).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('expenses_category'),
              }),
              type: 'success',
            });

            return fromActions.updateSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ expense_category_id, propertiesIds }) =>
        this.dataService.delete(expense_category_id, propertiesIds).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant('expenses_category'),
              }),
              type: 'success',
            });

            return fromActions.deleteSuccess({ expense_category_id });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
