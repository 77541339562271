import { Action, createReducer, on } from '@ngrx/store';

import { ExpensesCategory } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';
import { featureAdapter } from './state';

const generateDefaultVatQuotesMap = (
  categoryMap,
  category: ExpensesCategory,
) => {
  const { id, properties } = category;

  categoryMap = {
    ...categoryMap,
    [id]: (properties || []).reduce(
      (propertiesMap, { property_id, default_vat_quote_id }) =>
        (propertiesMap = {
          ...propertiesMap,
          [property_id]: default_vat_quote_id,
        }),
      {},
    ),
  };

  return categoryMap;
};

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { data }) =>
    featureAdapter.setAll(data, {
      ...state,
      isLoading: false,
      error: null,
      defaultVatQuotesMap: (data || []).reduce(generateDefaultVatQuotesMap, {}),
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadDetailsRequest, (state) => ({
    ...state,
    details: null,
    error: null,
  })),
  on(
    fromActions.loadDetailsSuccess,
    (state, { data: { translations, properties, ...data } }) => ({
      ...state,
      details: {
        ...data,
        property_id: properties.map(({ property_id }) => property_id),
        default_vat_quote_id: properties[0].default_vat_quote_id || 0,
        translations: translations.reduce((trans, tran) => {
          const { locale, label: name } = tran;
          return (trans = {
            ...trans,
            [locale]: { name },
          });
        }, {}),
      },
      error: null,
    }),
  ),
  on(fromActions.loadDetailsFailure, (state, { error }) => ({
    ...state,
    details: null,
    error,
  })),

  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { expense_category_id }) =>
    featureAdapter.removeOne(expense_category_id, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
  on(fromActions.resetDetails, (state) => ({ ...state, details: null })),
);

export function expensesCategoriesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
